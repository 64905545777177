$(function () {
  const menu = document.querySelector('.menu');
  const nav = document.querySelector('.nav-bar');

  menu.addEventListener('click', () => {
    menu.classList.toggle('active');
    nav.classList.toggle('active');
  });

  // let body = null;
  // let interval = setInterval(() => {
  //   // HhJ8keLZ1SBupKzmsP0V _C_TCiqAjdTOkIIzyy7N _2eZHnh_PMhyzt7w_zi7 Rkt9F4BV7O3LQSywHdJy
  //   body = document.querySelector('.Es8downQlxiASDyltHt');

  //   if (body) {
  //     console.log(body);
  //     clearInterval(interval);
  //   }
  // }, 1000);

  $('.owl-carousel').owlCarousel({
    autoplay: true,
    loop: true,
    dots: false,
    nav: true,
    responsiveClass: true,
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 2,
      },
      1000: {
        items: 3,
      },
    },
  });

  const marquee = (element) => {
    if (!element) return;

    let marqueeList = element.querySelector('ul');

    let count = 0;
    let clone;
    let isPaused = false;

    let marqueeHandler = () => {
      if (isPaused) {
        return;
      }

      let dimension = marqueeList.getBoundingClientRect();

      if (dimension.right === window.innerWidth) {
        clone = marqueeList.cloneNode(true);
        element.appendChild(clone);
      }

      if (dimension.right === 0) {
        let children = element.getElementsByTagName('ul');
        element.removeChild(children[0]);
        count = -20;
        marqueeList = element.querySelector('ul');
      }

      element.style.transform = `translateX(${-count}px)`;
      ++count;
    };

    setInterval(marqueeHandler, 30);

    element.addEventListener('mouseenter', () => {
      isPaused = true;
    });

    element.addEventListener('mouseleave', () => {
      isPaused = false;
    });
  };

  const marquee1 = document.getElementById('marquee-effect-1');
  const marquee2 = document.getElementById('marquee-effect-2');
  marquee(marquee1);

  setTimeout(() => marquee(marquee2), 3000);
});
